import { formatDate } from 'date-fns';
import { httpsCallable } from 'firebase/functions';

import { functions } from '@/lib/firebase';

export const downloadGeneratedCSV = async (
  recordStatus: string,
  scholarYear: string,
  formuleType: string,
  onlyValidated: boolean,
  selectedFields: {
    student: boolean;
    referent: boolean;
    pastScolarity: boolean;
    formule: boolean;
    payment: boolean;
  },
) => {
  try {
    const getCSV = httpsCallable(functions, 'generateCSVFile');

    const response = await getCSV({
      recordStatus,
      scholarYear,
      formuleType,
      onlyValidated,
      selectedFields,
    });

    const element = document.createElement('a');

    element.setAttribute(
      'href',
      // @ts-expect-error -- Typescript doesn't know about the response type
      `data:text/csv;charset=utf-8,${encodeURI(response.data)}`,
    );
    element.setAttribute(
      'download',
      `inscriptions_prevision_${formatDate(new Date(), 'dd-MM-yyyy_HH-mm-ss')}_${recordStatus}.csv`,
    );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (error) {
    console.error('Error while downloading CSV:', error);
    throw new Error('Error while downloading CSV');
  }
};
