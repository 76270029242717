// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import * as Sentry from '@sentry/react';
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';

import { functions } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { nukeLocalData } from '@/utils/cleaner';

export const Route = createFileRoute('/test')({
  loader: async () => {
    if (process.env.NODE_ENV === 'production') {
      throw notFound();
    }
  },
  component: () => <TestComponent />,
  notFoundComponent: () => <NotFoundPage />,
});

const TestComponent = () => {
  const navigate = useNavigate();

  const testLeCsv = async () => {
    const getCSV = httpsCallable(functions, 'generateCSVFile');

    const response = await getCSV({
      recordStatus: 'submitted',
      scholarYear: '2024/2025',
      formuleType: 'Sciences Po / IEP - Formule Complète',
      onlyValidated: true,
      selectedFields: {
        student: false,
        referent: false,
        pastScolarity: false,
        formule: true,
        payment: false,
      },
    });

    const element = document.createElement('a');

    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURI(response.data)}`,
    );
    element.setAttribute('download', 'test.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    console.log('Test page mounted');
    // Provoquer une erreur
    try {
      console.log('Test error from console!');
      throw new Error('Test error from Sentry!');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }, []);

  return (
    <div>
      <button
        onClick={() => {
          nukeLocalData(navigate);
        }}
      >
        Nuke localdata
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={testLeCsv}>Test le csv</button> <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={() => methodDoesNotExist()}>Break the world</button>;
    </div>
  );
};
