import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import ls from 'localstorage-slim';

import { functions } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { PaymentPage } from '@/pages/inscriptions/payment';
import { LoaderPage } from '@/pages/loader';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps/seven')({
  beforeLoad: () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw redirect({ to: '/steps/one' });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    if (!pendingInscription.formProgress || pendingInscription.formProgress + 1 < 7) {
      throw redirect({
        to: '/steps/six',
      });
    }
  },
  loader: async () => {
    const createPayment = httpsCallable(functions, 'createPayment');

    try {
      const result = await createPayment();
      if (result.data === undefined) throw new Error('No data returned');

      return result.data;
    } catch (error) {
      console.error(error);
      throw notFound();
    }
  },
  component: () => <PaymentPage />,
  pendingComponent: () => (
    <LoaderPage
      bgClassName="bg-gray-100 h-screen w-full"
      pointClassName="bg-previsionblue"
    />
  ),
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
