export const pageTypeSelector = (path: string) => {
  if (path.includes('admin/inscriptions/pending')) {
    return 'pending';
  } else if (path.includes('admin/inscriptions/cancelled')) {
    return 'cancelled';
  } else if (path.includes('admin/inscriptions/archived')) {
    return 'archived';
  } else if (path.includes('admin/inscriptions/done')) {
    return 'submitted';
  } else {
    return 'pending';
  }
};
