import { Link, useRouter } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import { ChevronLeft, CreditCard, Download, LoaderCircle } from 'lucide-react';
import { FormEvent, useState } from 'react';
import { toast } from 'sonner';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import {
  changeDepositToPaid,
  changeDepositToRefunded,
  switchRecordStatus,
  switchRecordValidation,
} from '@/data/form-handlers';
import { functions } from '@/lib/firebase';
import { Payment, SchoolRecordEnforced } from '@/types/types';
import { downloadCertificate } from '@/utils/downloadCertificate';

import { ErrorModal } from '../edit-modal';

export const RecordHeader = ({
  lastname,
  firstname,
  refundStatus,
  depositStatus,
  formationCost,
  isRecordValidated,
  previousSchoolRecord,
  year,
}: {
  lastname: string;
  firstname: string;
  refundStatus: boolean;
  depositStatus: string;
  formationCost: number;
  isRecordValidated: boolean;
  previousSchoolRecord: SchoolRecordEnforced;
  year: string;
}) => {
  const router = useRouter();

  const [modalType, setModalType] = useState(
    '' as
      | 'cancel'
      | 'delete'
      | 'archive'
      | 'validate'
      | 'payment'
      | 'warning'
      | 'restore',
  );
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelNote, setCancelNote] = useState('');
  const [refund, setRefund] = useState(false);
  const [warningModalTitle, setWarningModalTitle] = useState('');
  const [warningModalAction, setWarningModalAction] = useState(() => {});

  const [validateWaiting, setValidateWaiting] = useState(false);
  const [archiveWaiting, setArchiveWaiting] = useState(false);
  const [cancelWaiting, setCancelWaiting] = useState(false);
  const [warningWaiting, setWarningWaiting] = useState(false);
  const [changeDepositStatus, setChangeDepositStatus] = useState(false);

  const backButtonPath = `../${previousSchoolRecord.recordStatus === 'submitted' ? 'done' : previousSchoolRecord.recordStatus === 'deleted' ? 'cancelled' : previousSchoolRecord.recordStatus}`;

  const depositPaymentMethod =
    (previousSchoolRecord.payments &&
      previousSchoolRecord.payments.schedule &&
      previousSchoolRecord.payments.schedule.find(
        (payment) => payment.paymentType === 'accompte',
      )?.paymentMethod) ||
    '';
  const depositRef =
    (previousSchoolRecord.payments &&
      previousSchoolRecord.payments.schedule &&
      previousSchoolRecord.payments.schedule.find(
        (payment) => payment.paymentType === 'accompte',
      )?.paymentReference) ||
    '';

  const validateRecord = async () => {
    setValidateWaiting(true);
    const res = await switchRecordValidation(previousSchoolRecord, !isRecordValidated);

    if (res) {
      toast(`Le dossier a bien été ${isRecordValidated ? 'in' : ''}validé`);
      router.invalidate();
      setValidateWaiting(false);
    } else {
      toast('Une erreur est survenue lors de la modification du dossier');
      setValidateWaiting(false);
    }
  };

  const archivedRecord = async () => {
    const newStatus =
      previousSchoolRecord.recordStatus === 'pending' ? 'archived' : 'pending';
    setArchiveWaiting(true);
    const res = await switchRecordStatus(previousSchoolRecord, newStatus);

    if (res) {
      toast(`Le dossier a bien été ${newStatus === 'pending' ? 'dés' : ''}archivé`);
      router.invalidate();
      setArchiveWaiting(false);
    } else {
      toast('Une erreur est survenue lors de la modification du dossier');
      setArchiveWaiting(false);
    }
  };

  const cancelRecord = async () => {
    setCancelWaiting(true);
    const newStatus =
      previousSchoolRecord.recordStatus === 'cancelled' ? 'submitted' : 'cancelled';

    const alteredRecord = {
      ...previousSchoolRecord,
      recordNote: cancelNote,
    };

    //Find the accompte payment inside previousSchoolRecord.payments.schedule
    const depositPayment =
      previousSchoolRecord &&
      previousSchoolRecord.payments &&
      previousSchoolRecord.payments.schedule &&
      previousSchoolRecord.payments.schedule.find(
        (payment) => payment.paymentType === 'accompte',
      );

    let newPayments: Payment;
    if (previousSchoolRecord.payments.schedule && depositPayment) {
      if (refund) {
        depositPayment.paymentStatus = 'refunded';
      }

      newPayments = {
        ...previousSchoolRecord.payments,
        refunded: refund,
        schedule: previousSchoolRecord.payments.schedule.map((payment) =>
          payment.paymentType === 'deposit' ? depositPayment : payment,
        ),
      };

      alteredRecord.payments = newPayments;
    }

    const res = await switchRecordStatus(alteredRecord, newStatus);

    if (res) {
      toast("L'inscription a bien été annulée");
      router.invalidate();
      setCancelWaiting(false);
      setCancelModalOpen(false);
    } else {
      toast("Une erreur est survenue lors de l'annulation de l'inscription");
      setCancelWaiting(false);
    }
  };

  const deleteRecord = async () => {
    setCancelWaiting(true);
    const res = await switchRecordStatus(previousSchoolRecord, 'deleted');

    if (res) {
      toast("L'inscription a bien été annulée");
      router.invalidate();
      setCancelWaiting(false);
    } else {
      toast("Une erreur est survenue lors de l'annulation de l'inscription");
      setCancelWaiting(false);
    }
  };

  const markDepositAsPaid = async (paymentMethod: string, chequeNumber?: string) => {
    setChangeDepositStatus(true);

    if (paymentMethod === '') {
      toast('Veuillez selectionner une méthode de paiement');
      setChangeDepositStatus(false);
      return;
    }

    const sendEmailToUser = httpsCallable(functions, 'sendEmailToUser');

    const res = await changeDepositToPaid(
      previousSchoolRecord,
      paymentMethod,
      chequeNumber,
    );

    const email = previousSchoolRecord.student?.contact.email;

    if (res) {
      if (email) {
        await sendEmailToUser({
          to: email,
          subject: 'Confirmation de règlement des frais de préinscription',
          htmlTemplateName: 'preinscriptionEmailTemplate',
        }).catch((error) => {
          console.error(`Erreur lors de l'envoi de l'email de confirmation: ${error}`);
        });
      }

      toast("L'acompte a bien été marqué comme payé");
      router.invalidate();
      setChangeDepositStatus(false);
      setModalType('' as 'cancel' | 'delete' | 'archive' | 'validate' | 'payment');
      setCancelModalOpen(false);
      return;
    } else {
      toast("Une erreur est survenue lors de la modification de l'acompte");
      setChangeDepositStatus(false);

      return;
    }
  };

  const markDepositAsRefunded = async () => {
    setWarningWaiting(true);
    const res = await changeDepositToRefunded(previousSchoolRecord);

    if (res) {
      toast("L'acompte a bien été marqué comme remboursé");
      router.invalidate();
      setWarningWaiting(false);
      setModalType('' as 'cancel' | 'delete' | 'archive' | 'validate' | 'payment');
      setCancelModalOpen(false);
    } else {
      toast("Une erreur est survenue lors de la modification de l'acompte");
      setWarningWaiting(false);
    }
  };

  const restoreRecord = async () => {
    setWarningWaiting(true);
    const res = await switchRecordStatus(previousSchoolRecord, 'submitted');
    //If the record is restored, we need to remove the refunded status from the deposit
    const depositPayment =
      previousSchoolRecord &&
      previousSchoolRecord.payments &&
      previousSchoolRecord.payments.schedule &&
      previousSchoolRecord.payments.schedule.find(
        (payment) => payment.paymentType === 'accompte',
      );

    let newPayments: Payment;
    if (previousSchoolRecord.payments.schedule && depositPayment) {
      depositPayment.paymentStatus = 'paid';

      newPayments = {
        ...previousSchoolRecord.payments,
        refunded: false,
        schedule: previousSchoolRecord.payments.schedule.map((payment) =>
          payment.paymentType === 'deposit' ? depositPayment : payment,
        ),
      };

      const alteredRecord = {
        ...previousSchoolRecord,
        payments: newPayments,
      };

      await switchRecordStatus(alteredRecord, 'submitted');
    }

    if (res) {
      toast("L'inscription a bien été restaurée");
      router.invalidate();
      setWarningWaiting(false);
      setModalType('' as 'cancel' | 'delete' | 'archive' | 'validate' | 'payment');
      setCancelModalOpen(false);
    } else {
      toast("Une erreur est survenue lors de la restauration de l'inscription");
      setWarningWaiting(false);
    }
  };

  return (
    <>
      <div className="space-y-4">
        <div className="flex w-full justify-between">
          <div className="flex space-x-2">
            <Button
              asChild
              className="h-16 w-16 rounded-full border border-none bg-transparent p-0 text-previsionblue hover:bg-black/10 hover:text-previsionorange"
            >
              {/* @ts-expect-error -- Typescript dosen't like when we build url */}
              <Link to={backButtonPath}>
                <ChevronLeft size={50} strokeWidth={3} />
              </Link>
            </Button>
            <div className="my-auto ">
              <h2 className="max-w-3xl overflow-hidden text-ellipsis text-2xl font-bold text-previsionblue">
                {`${lastname.toUpperCase()} ${firstname.toUpperCase()}`}
              </h2>
              <div className="flex flex-wrap justify-start">
                <Badge
                  className="mx-1 my-2"
                  variant={isRecordValidated ? 'green' : 'red'}
                >
                  {isRecordValidated ? 'Validé' : 'Non validé'}
                </Badge>
                <Badge
                  className="mx-1 my-2"
                  variant={depositStatus === 'paid' ? 'green' : 'red'}
                >
                  {depositStatus === 'paid' && !refundStatus && 'Acompte payé'}
                  {depositStatus !== 'paid' && !refundStatus && 'Acompte non payé'}
                  {refundStatus && 'Remboursée'}
                </Badge>
                <p className="mx-1 my-2 font-medium">{formationCost} €</p>
              </div>
            </div>
          </div>
          {previousSchoolRecord.recordStatus === 'submitted' && (
            <Button
              className="my-auto hidden min-w-24 lg:block"
              onClick={validateRecord}
              disabled={validateWaiting}
              variant="dashboardOrange"
            >
              {validateWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!validateWaiting && isRecordValidated && 'Invalider le dossier'}
              {!validateWaiting && !isRecordValidated && 'Valider le dossier'}
            </Button>
          )}
          {(previousSchoolRecord.recordStatus === 'pending' ||
            previousSchoolRecord.recordStatus === 'archived') && (
            <Button
              className="my-auto hidden min-w-24 lg:block"
              variant="dashboardYellow"
              onClick={archivedRecord}
              disabled={archiveWaiting}
            >
              {archiveWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!archiveWaiting &&
                previousSchoolRecord.recordStatus === 'pending' &&
                'Archiver'}
              {!archiveWaiting &&
                previousSchoolRecord.recordStatus === 'archived' &&
                'Désarchiver'}
            </Button>
          )}
          {previousSchoolRecord.recordStatus === 'cancelled' && (
            <div className="flex flex-col justify-end space-y-1">
              <Button
                className="my-auto hidden min-w-24 border border-red-700 bg-red-700 text-white hover:bg-white hover:text-previsionorange lg:block"
                onClick={deleteRecord}
                disabled={cancelWaiting}
              >
                {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
                {!cancelWaiting && "Supprimer l'inscription"}
              </Button>

              {!previousSchoolRecord.payments.refunded && (
                <Button
                  className="my-auto hidden min-w-24 lg:block"
                  variant={'dashboardOrange'}
                  onClick={() => {
                    setWarningModalTitle(
                      "Êtes-vous sûr de vouloir marquer l'acompte comme remboursé ?",
                    );
                    setWarningModalAction(() => markDepositAsRefunded);
                    setModalType('warning');
                    setCancelModalOpen(!cancelModalOpen);
                  }}
                  disabled={cancelWaiting}
                >
                  {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
                  {!cancelWaiting && 'Marquer comme remboursée'}
                </Button>
              )}
            </div>
          )}
        </div>
        {previousSchoolRecord.recordStatus === 'submitted' && (
          <div className="flex flex-col justify-start lg:flex-row">
            <div className="flex flex-col lg:flex-row">
              {depositPaymentMethod === 'stripe' && (
                <Button className="mx-2 my-1" variant="dashboardOrange" asChild>
                  <a
                    href={`https://dashboard.stripe.com/payments/${depositRef}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <CreditCard className="mr-2" /> Voir sur Stripe
                  </a>
                </Button>
              )}

              {depositPaymentMethod === 'cheque' && (
                <div className="mx-2 my-1 flex justify-center rounded-md border border-previsionorange bg-white p-2">
                  <p className="my-auto hidden lg:block">
                    Numéro du chèque: {depositRef}
                  </p>
                  <p className="my-auto lg:hidden">Chèque: {depositRef}</p>
                </div>
              )}

              {depositPaymentMethod === 'cash' && (
                <div className="mx-2 my-1 flex justify-center rounded-md border border-previsionorange bg-white p-2">
                  <p className="my-auto hidden lg:block">Espèces</p>
                  <p className="my-auto lg:hidden">Espèces</p>
                </div>
              )}

              {depositPaymentMethod === 'bank-transfer' && (
                <div className="mx-2 my-1 flex justify-center rounded-md border border-previsionorange bg-white p-2">
                  <p className="my-auto hidden lg:block">Virement bancaire</p>
                  <p className="my-auto lg:hidden">Virement bancaire</p>
                </div>
              )}

              {depositPaymentMethod === 'credit-card' && (
                <div className="mx-2 my-1 flex justify-center rounded-md border border-previsionorange bg-white p-2">
                  <p className="my-auto hidden lg:block">Carte bancaire</p>
                  <p className="my-auto lg:hidden">Carte bancaire</p>
                </div>
              )}

              {depositPaymentMethod === 'bank-debit' && (
                <div className="mx-2 my-1 flex justify-center rounded-md border border-previsionorange bg-white p-2">
                  <p className="my-auto hidden lg:block">Prélèvement</p>
                  <p className="my-auto lg:hidden">Prélèvement</p>
                </div>
              )}

              <Separator orientation="vertical" className="hidden bg-black/30 lg:block" />
              <Separator className="bg-black/30 lg:hidden" />
            </div>

            <Button
              className="mx-2 my-1 block border lg:hidden"
              onClick={validateRecord}
              disabled={validateWaiting}
              variant="dashboardOrange"
            >
              {validateWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!validateWaiting && isRecordValidated && 'Invalider le dossier'}
              {!validateWaiting && !isRecordValidated && 'Valider le dossier'}
            </Button>
            <Button
              onClick={() => {
                toast('Génération du certificat de scolarité en cours...');
                downloadCertificate(
                  firstname + ' ' + lastname,
                  year,
                  previousSchoolRecord.student.civility,
                );
              }}
              className="mx-2 my-1"
              variant="dashboardOrange"
            >
              <Download className="mr-2" /> Télecharger le certificat de scolarité
            </Button>
            <Button
              className="mx-2 my-1"
              variant="dashboardRed"
              onClick={() => {
                setModalType('cancel');
                setCancelModalOpen(!cancelModalOpen);
              }}
            >
              {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!cancelWaiting && "Annuler l'inscription"}
            </Button>
          </div>
        )}
        {previousSchoolRecord.recordStatus === 'pending' &&
          previousSchoolRecord.formProgress >= 6 &&
          depositStatus !== 'paid' &&
          !refundStatus && (
            <div className="flex flex-col justify-start lg:flex-row">
              <Button
                className="mx-2 my-auto min-w-24"
                variant="dashboardYellow"
                onClick={() => {
                  setModalType('payment');
                  setCancelModalOpen(!cancelModalOpen);
                }}
              >
                Marquer l'accompte comme payé
              </Button>
            </div>
          )}
        {previousSchoolRecord.recordStatus === 'cancelled' && (
          <div className="flex flex-col justify-start lg:flex-row">
            <Button
              className="mx-2 my-auto min-w-24"
              variant="dashboardOrange"
              onClick={() => {
                setModalType('restore');
                setCancelModalOpen(!cancelModalOpen);
              }}
            >
              Restaurer l'inscription
            </Button>
          </div>
        )}
        {/* Display on little screen */}
        {(previousSchoolRecord.recordStatus === 'pending' ||
          previousSchoolRecord.recordStatus === 'archived') && (
          <div className="flex flex-col justify-start lg:flex-row">
            <Button
              className="mx-2 my-auto min-w-24 lg:hidden"
              variant="dashboardYellow"
              onClick={archivedRecord}
              disabled={archiveWaiting}
            >
              {archiveWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!archiveWaiting &&
                previousSchoolRecord.recordStatus === 'pending' &&
                'Archiver'}
              {!archiveWaiting &&
                previousSchoolRecord.recordStatus === 'archived' &&
                'Désarchiver'}
            </Button>
          </div>
        )}

        {previousSchoolRecord.recordStatus === 'cancelled' && (
          <div className="flex flex-col justify-start space-y-1 lg:flex-row">
            <Button
              className="mx-2 my-auto min-w-24 border border-red-700 bg-red-700 text-white hover:bg-white hover:text-previsionorange lg:hidden"
              onClick={deleteRecord}
              disabled={cancelWaiting}
            >
              {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
              {!cancelWaiting && "Supprimer l'inscription"}
            </Button>
            {!previousSchoolRecord.payments.refunded && (
              <Button
                className="mx-2 my-auto min-w-24 lg:hidden"
                variant="dashboardOrange"
                onClick={() => {
                  setWarningModalTitle(
                    "Êtes-vous sûr de vouloir marquer l'acompte comme remboursé ?",
                  );
                  setWarningModalAction(() => markDepositAsRefunded);
                  setModalType('warning');
                  setCancelModalOpen(!cancelModalOpen);
                }}
                disabled={cancelWaiting}
              >
                {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
                {!cancelWaiting && 'Marquer comme remboursée'}
              </Button>
            )}
          </div>
        )}
        {/* END -- Display on little screen */}
      </div>
      <Dialog open={cancelModalOpen} onOpenChange={(open) => setCancelModalOpen(open)}>
        {modalType === 'cancel' && (
          <CancelModal
            cancelRecord={cancelRecord}
            setRefund={setRefund}
            refund={refund}
            setCancelNote={setCancelNote}
            cancelWaiting={cancelWaiting}
          />
        )}
        {modalType === 'payment' && (
          <MarkDepositAsPaidModal
            changeDepositStatus={changeDepositStatus}
            markDepositAsPaid={markDepositAsPaid}
          />
        )}
        {modalType === 'warning' && (
          <AvertissementModal
            buttonText="Confirmer"
            title={warningModalTitle}
            /* @ts-expect-error -- warningModalAction is a void function */
            action={warningModalAction}
            cancelAction={() => setCancelModalOpen(false)}
            cancelText="Annuler"
            isLoading={warningWaiting}
          />
        )}
        {modalType === 'restore' && (
          <AvertissementModal
            buttonText="Confirmer"
            title={"Êtes-vous sûr de vouloir restaurer l'inscription ?"}
            action={() => {
              restoreRecord();
            }}
            cancelAction={() => setCancelModalOpen(false)}
            cancelText="Annuler"
            isLoading={warningWaiting}
          />
        )}
        {modalType !== 'cancel' &&
          modalType !== 'delete' &&
          modalType !== 'archive' &&
          modalType !== 'validate' &&
          modalType !== 'payment' &&
          modalType !== 'warning' &&
          modalType !== 'restore' && <ErrorModal error="Une erreur est survenue !" />}
      </Dialog>
    </>
  );
};

const CancelModal = ({
  cancelRecord,
  setRefund,
  refund,
  setCancelNote,
  cancelWaiting,
}: {
  cancelRecord: () => Promise<void>;
  setRefund: (value: boolean) => void;
  refund: boolean;
  setCancelNote: (value: string) => void;
  cancelWaiting: boolean;
}) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Êtes-vous sûr de vouloir annuler l'inscription ?</DialogTitle>
      </DialogHeader>
      <form
        className="flex flex-col space-y-2"
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          cancelRecord();
        }}
      >
        <div className="flex items-center space-x-2">
          <Switch
            id="refund"
            className="data-[state=checked]:bg-previsionorange data-[state=unchecked]:bg-gray-200"
            onCheckedChange={(value) => {
              setRefund(value);
            }}
            checked={refund}
          />
          <Label htmlFor="refund" className="my-auto">
            Remboursée
          </Label>
        </div>
        <div className="flex justify-end space-x-2">
          <Textarea
            id="cancelNote"
            className="w-full"
            placeholder="Note sur l'annulation."
            onChange={(event) => setCancelNote(event.target.value)}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <Button
            type="submit"
            className="border border-red-700 bg-red-700 text-white hover:bg-white hover:text-previsionorange"
            disabled={cancelWaiting}
          >
            {cancelWaiting && <LoaderCircle className="mx-auto animate-spin" />}
            {!cancelWaiting && (
              <>
                <span className="hidden lg:block">
                  Je confirme vouloir annuler l'inscription
                </span>
                <span className="lg:hidden">Confirmer</span>
              </>
            )}
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

const MarkDepositAsPaidModal = ({
  changeDepositStatus,
  markDepositAsPaid,
}: {
  changeDepositStatus: boolean;
  markDepositAsPaid: (paymentMethod: string, chequeNumber?: string) => Promise<void>;
}) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [chequeNumber, setChequeNumber] = useState('');

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Marquer l'acompte comme payé</DialogTitle>
      </DialogHeader>
      <form
        className="flex flex-col space-y-2"
        onSubmit={(event) => {
          event.preventDefault();
          markDepositAsPaid(paymentMethod, chequeNumber);
        }}
      >
        <div className="space-y-2">
          <Select
            onValueChange={(e) => {
              setPaymentMethod(e);
            }}
            value={paymentMethod}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selectionner une méthode de paiement" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="cheque">Chèque</SelectItem>
                <SelectItem value="cash">Espèces</SelectItem>
                <SelectItem value="credit-card">Carte bancaire</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          {paymentMethod === 'cheque' && (
            <Input
              id="chequeNumber"
              className="w-full"
              placeholder="Numero de chèque"
              onChange={(event) => setChequeNumber(event.target.value)}
            />
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <Button
            type="submit"
            className="border border-previsionblue bg-previsionblue text-white hover:bg-white hover:text-previsionblue"
            disabled={changeDepositStatus || paymentMethod === ''}
          >
            {changeDepositStatus && <LoaderCircle className="mx-auto animate-spin" />}
            {!changeDepositStatus && (
              <>
                <span className="hidden lg:block">
                  Je confirme vouloir marquer l'acompte comme payé
                </span>
                <span className="lg:hidden">Confirmer</span>
              </>
            )}
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

const AvertissementModal = ({
  buttonText,
  title,
  action,
  cancelAction,
  cancelText,
  isLoading,
}: {
  buttonText: string;
  title: string;
  action: () => void;
  cancelAction: () => void;
  cancelText: string;
  isLoading?: boolean;
}) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <form className="flex flex-col space-y-2">
        <div className="flex justify-end space-x-2">
          <Button
            type="button"
            variant="dashboardOrange"
            onClick={action}
            disabled={isLoading}
          >
            {buttonText}
          </Button>
          <Button
            type="button"
            className="border border-red-700 bg-red-700 text-white hover:bg-white hover:text-previsionorange"
            onClick={cancelAction}
            disabled={isLoading}
          >
            {cancelText}
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};
