import { formatDate } from 'date-fns';
import { SquarePen } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { DataCell } from '@/components/ui/record-cell';
import { Person } from '@/types/types';

export const ReferentInformations = ({
  infos,
  triggerEdit,
}: {
  infos: Person;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">
          INFORMATION DU RESPONSABLE LÉGAL
        </h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('referent');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <div className="">
        <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto ">
          Coordonnées
        </h4>
        <div className="flex flex-wrap">
          <DataCell
            title="Civilité"
            value={infos.civility === 'M' ? 'Monsieur' : 'Madame'}
          />
          <DataCell title="Prénom" value={infos.firstname} />
          <DataCell title="Nom" value={infos.lastname} />
          <DataCell
            title="Date de naissance"
            //@ts-expect-error -- Firebase envoi un type de Date particulier
            value={formatDate(infos.birthdate.toDate(), 'dd/MM/yyyy')}
          />
          <DataCell title="Lieu de naissance" value={infos.placeOfBirth} />
        </div>
        <div>
          <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
            Contact
          </h4>
          <div className="flex flex-wrap">
            <DataCell title="Téléphone mobile" value={infos.contact.mobilePhone} />
            <DataCell title="Téléphone fixe" value={infos.contact.landlinePhone} />
            <DataCell title="Email" value={infos.contact.email} />
          </div>
        </div>
      </div>
      <div>
        <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">Adresse</h4>
        <div className="flex flex-wrap">
          <DataCell title="Adresse" value={infos.address.street} />
          <DataCell title="Code postal" value={infos.address.postalCode} />
          <DataCell title="Ville" value={infos.address.city} />
          <DataCell title="Pays" value={infos.address.country} />
        </div>
      </div>
    </div>
  );
};
