import { useLoaderData, useMatchRoute } from '@tanstack/react-router';
import { FileDown } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { inscriptionsColumn } from '@/components/data-table/columns';
import { InscriptionsDataTable } from '@/components/data-table/table';
import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { InscriptionRow } from '@/types/types';
import { downloadGeneratedCSV } from '@/utils/downloadGeneratedCSV';
import { pageTypeSelector } from '@/utils/pageTypeSelector';

export const InscriptionsTablePage = ({
  from,
  title,
}: {
  from: string;
  title: string;
}) => {
  const matchRoute = useMatchRoute();
  const isPaidPage = matchRoute({ to: '/admin/inscriptions/done' }) ? true : false;

  const [dialogTrigger, setDialogTrigger] = useState(false);
  const [exportStyle, setExportStyle] = useState('ALL');
  const [exportFilters, setExportFilters] = useState({
    recordStatus: pageTypeSelector(from),
    scholarYear: 'all',
    formuleType: 'all',
    onlyValidated: false,
    selectedFields: {
      student: true,
      referent: true,
      pastScolarity: true,
      formule: true,
      payment: true,
    },
  });

  const {
    data,
    yearList,
    formuleList,
  }: { data: InscriptionRow[]; yearList: string[]; formuleList: string[] } =
    useLoaderData({
      // @ts-expect-error -- Type is coming from router, find how to type it
      from,
    });

  return (
    <section className="min-h-[calc(100vh-5rem)] w-full bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle title={title} />
      <InscriptionsDataTable
        columns={inscriptionsColumn}
        data={data}
        yearList={yearList}
        formuleList={formuleList}
        recordValidatedSelector={isPaidPage ? true : false}
      />

      <div className="fixed bottom-[5%] right-[1%] hidden md:block">
        <Button
          className="h-12 w-12 rounded-full bg-previsionorange p-2 text-white transition-all  hover:bg-previsionorange/60"
          title="Télécharger le CSV"
          onClick={() => setDialogTrigger(true)}
        >
          <FileDown size={42} />
        </Button>
      </div>
      <div className="w-full p-4 md:hidden">
        <Button
          className="w-full bg-previsionorange text-white  transition-all hover:bg-previsionorange/60"
          title="Télécharger le CSV"
          onClick={() => setDialogTrigger(true)}
        >
          <FileDown size={30} className="m-4" /> <p>Télécharger le CSV</p>
        </Button>
      </div>

      <Dialog
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setDialogTrigger(false);
          } else {
            setDialogTrigger(true);
          }
        }}
        open={dialogTrigger}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Exporter les données en CSV</DialogTitle>
          </DialogHeader>
          <section className="md:space-y-2">
            <div className="flex w-full flex-col lg:flex-row">
              <Select
                defaultValue="ALL"
                value={exportStyle}
                onValueChange={(value) => {
                  if (value === 'ALL') {
                    setExportFilters({
                      recordStatus: 'submitted',
                      scholarYear: 'all',
                      formuleType: 'all',
                      onlyValidated: true,
                      selectedFields: {
                        student: true,
                        referent: true,
                        pastScolarity: true,
                        formule: true,
                        payment: true,
                      },
                    });
                  }

                  setExportStyle(value);
                }}
              >
                <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                  <SelectValue placeholder="Selectionner une année scolaire" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ALL">Toutes les données</SelectItem>
                  <SelectItem value="personalized">Personnalisé</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex w-full flex-col lg:flex-row">
              <Select
                disabled={exportStyle === 'ALL'}
                value={exportFilters.scholarYear}
                onValueChange={(value) => {
                  setExportFilters({ ...exportFilters, scholarYear: value });
                }}
              >
                <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                  <SelectValue placeholder="Selectionner une année scolaire" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Toutes les années scolaires</SelectItem>
                  {yearList.map((year) => (
                    <SelectItem key={year} value={year}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Select
                disabled={exportStyle === 'ALL'}
                value={exportFilters.formuleType}
                onValueChange={(value) => {
                  setExportFilters({ ...exportFilters, formuleType: value });
                }}
              >
                <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                  <SelectValue placeholder="Selectionner une année scolaire" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Toutes les formules</SelectItem>
                  {formuleList.map((formule) => (
                    <SelectItem key={formule} value={formule}>
                      {formule}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mx-2 my-1 flex w-full items-center space-x-2 bg-white lg:my-auto">
              <Switch
                disabled={exportStyle === 'ALL'}
                id="exportValidatedOnly"
                className="data-[state=checked]:bg-green-600 data-[state=unchecked]:bg-gray-200"
                defaultChecked={exportFilters.onlyValidated}
                onCheckedChange={(checked) => {
                  setExportFilters({ ...exportFilters, onlyValidated: checked });
                }}
                checked={exportFilters.onlyValidated}
              />
              <Label htmlFor="exportValidatedOnly">
                Inscriptions validées uniquement
              </Label>
            </div>
          </section>
          <section className="mx-2 grid grid-cols-2 gap-2 md:space-y-1">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="studentData"
                disabled={exportStyle === 'ALL'}
                defaultChecked={true}
                checked={exportFilters.selectedFields.student}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    selectedFields: { ...exportFilters.selectedFields, student: checked },
                  });
                }}
              />
              <label
                htmlFor="studentData"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Données de l'étudiant
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="referentData"
                disabled={exportStyle === 'ALL'}
                defaultChecked={true}
                checked={exportFilters.selectedFields.referent}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    selectedFields: {
                      ...exportFilters.selectedFields,
                      referent: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="referentData"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Données de son responsable légal
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="scolarityData"
                disabled={exportStyle === 'ALL'}
                defaultChecked={true}
                checked={exportFilters.selectedFields.pastScolarity}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    selectedFields: {
                      ...exportFilters.selectedFields,
                      pastScolarity: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="scolarityData"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Données sur la scolarité passée
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="formuleData"
                disabled={exportStyle === 'ALL'}
                defaultChecked={true}
                checked={exportFilters.selectedFields.formule}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    selectedFields: {
                      ...exportFilters.selectedFields,
                      formule: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="formuleData"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Données de la formule choisie
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="paymentsData"
                disabled={exportStyle === 'ALL'}
                defaultChecked={true}
                checked={exportFilters.selectedFields.payment}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    selectedFields: {
                      ...exportFilters.selectedFields,
                      payment: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="paymentsData"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Données des paiments
              </label>
            </div>
          </section>

          <div className="mt-4 flex justify-end">
            <Button
              className="bg-previsionorange text-white hover:bg-previsionorange/60"
              title="Télécharger"
              onClick={() => {
                downloadGeneratedCSV(
                  exportFilters.recordStatus,
                  exportFilters.scholarYear,
                  exportFilters.formuleType,
                  exportFilters.onlyValidated,
                  exportFilters.selectedFields,
                );
                toast('Création du fichier CSV en cours');
              }}
            >
              Télécharger
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};
