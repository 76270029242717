import { createFileRoute, redirect } from '@tanstack/react-router';
import { collection, getDocs, query, where } from 'firebase/firestore';
import ls from 'localstorage-slim';

import { getScholarYear, getScolarityTypes } from '@/data/getters/admin';
import { db } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { OptionsChoicePage } from '@/pages/inscriptions/options-choice';
import { LoaderPage } from '@/pages/loader';
import { FirebaseCombobox, Formule, SchoolRecord, SortedFormules } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';
import { sortByPosition } from '@/utils/sortLists';

export const Route = createFileRoute('/steps/four')({
  beforeLoad: () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw redirect({ to: '/steps/one' });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    if (!pendingInscription.formProgress || pendingInscription.formProgress + 1 < 4) {
      throw redirect({
        to: '/steps/three',
      });
    }
  },
  loader: async () => {
    const pendingInscription: SchoolRecord | null =
      ls.get(LOCALSTORAGE_INSRIPTION_KEY) ?? null;

    const colRef = collection(db, 'formules');
    const firebaseQuery = query(colRef, where('status', '==', 'published'));
    const querySnapshot = await getDocs(firebaseQuery);
    const data: Formule[] = [];

    querySnapshot.forEach((doc) => {
      if (!doc.exists()) return null;
      data.push({
        id: doc.id as string,
        ...doc.data(),
      } as Formule);
    });

    const cleanedData: SortedFormules = data.reduce((acc: SortedFormules, objet) => {
      const { year, type, ...data } = objet;
      acc[year] = acc[year] || {};
      acc[year][type] = acc[year][type] || [];

      // @ts-expect-error -- ID ne sera jamais null
      acc[year][type].push({
        ...data,
        year,
        type,
      });
      return acc;
    }, {});

    Object.keys(cleanedData).forEach((year) => {
      Object.keys(cleanedData[year]).forEach((type) => {
        cleanedData[year][type] = sortByPosition(cleanedData[year][type]);
      });
    });

    let years: FirebaseCombobox[] = await getScholarYear(true);
    const yearsLength = years.length;
    let types: FirebaseCombobox[] = await getScolarityTypes(true);
    const typesLength = types.length;

    if (years && years.length > 0) {
      years = years.filter((year) => Object.keys(cleanedData).includes(year.value));

      const yearValues = new Set(years.map((year) => year.value));
      Object.keys(cleanedData).forEach((year) => {
        if (!yearValues.has(year)) {
          years.push({
            label: year,
            value: year,
            position: null,
            isAvailable: true,
            id: (yearsLength + 1).toString(),
          });
        }
      });

      years = sortByPosition(years);
    } else if (!years || years.length === 0) {
      years = [];
    }

    if (types && types.length > 0) {
      types = types.filter((type) =>
        Object.keys(cleanedData).some((year) =>
          Object.keys(cleanedData[year]).includes(type.value),
        ),
      );

      const typeValues = new Set(types.map((type) => type.value));
      Object.keys(cleanedData).forEach((year) => {
        Object.keys(cleanedData[year]).forEach((type) => {
          if (!typeValues.has(type)) {
            types.push({
              label: type,
              value: type,
              position: null,
              isAvailable: true,
              id: (typesLength + 1).toString(),
            });
          }
        });
      });

      types = sortByPosition(types);
    } else if (!types || types.length === 0) {
      types = [];
    }

    return {
      cleanedData,
      pendingInscription,
      filters: {
        years,
        types,
      },
    };
  },
  pendingComponent: () => <LoaderPage pointClassName="bg-previsionblue" />,
  component: () => <OptionsChoicePage />,
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
