import { SquarePen } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { DataCell } from '@/components/ui/record-cell';
import { Scolarity } from '@/types/types';

export const HighschoolDiplomaInformations = ({
  infos,
  triggerEdit,
}: {
  infos: Scolarity;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">BACCALAURÉAT</h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('scolarity');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <div className="space-y-1">
        {infos.diplomaSpecialities.map((speciality, index) => {
          if (index === 0)
            return (
              <DataCell
                key={index}
                title="Spécialité"
                value={speciality ? speciality : 'Non renseigné'}
              />
            );
          return <DataCell key={index} value={speciality ? speciality : ''} />;
        })}
        <DataCell
          title="Motivation"
          value={infos.motivation ? infos.motivation : 'Non renseigné'}
        />
      </div>
    </div>
  );
};
