import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { stepThreeSubmit } from '@/data/form-handlers';
import { scolaritySchema } from '@/data/forms-schema';
import { SchoolRecord } from '@/types/types';

export const ScolarityInfomations = () => {
  const navigate = useNavigate();

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const form = useForm<z.infer<typeof scolaritySchema>>({
    resolver: zodResolver(scolaritySchema),
    defaultValues: {
      diplomaSpecialityOne: pendingInscription?.scolarity?.diplomaSpecialities[0] ?? '',
      diplomaSpecialityTwo: pendingInscription?.scolarity?.diplomaSpecialities[1] ?? '',
      diplomaSpecialityThree: pendingInscription?.scolarity?.diplomaSpecialities[2] ?? '',
      studentMotivation: pendingInscription?.scolarity?.motivation ?? '',
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const response = await stepThreeSubmit(data);

          if (response) {
            navigate({
              to: '/steps/four',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit w-full bg-white px-7 py-4 text-black/60 lg:w-2/3"
      >
        <SectionTitle title="Mon baccalauréat et mes motivations" />

        <SectionSubtitle title="Spécialités" />
        <section className="mb-12 grid grid-cols-1 gap-4 md:gap-6">
          <FormField
            control={form.control}
            name="diplomaSpecialityOne"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Première spécialité</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="diplomaSpecialityTwo"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Deuxième spécialité</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="diplomaSpecialityThree"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Troisième spécialité (si troisième spécialité)
                </FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionSubtitle title="Motivations" />
        <section className="mb-12 grid grid-cols-1 gap-4 md:gap-6">
          <FormField
            control={form.control}
            name="studentMotivation"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Quel métier souhaitez-vous exercer plus tard et pourquoi ?
                </FormLabel>
                <Textarea
                  className="resize-none rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                  {...field}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>

        <div className="flex justify-between">
          <Link to={`/steps/two`}>
            <Button type="button" variant="outlinedBlue" className="w-32">
              Précédent
            </Button>
          </Link>
          <Button
            type="submit"
            variant="squaredBlue"
            className="w-32"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Suivant'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
