import { Outlet, useNavigate, useRouterState } from '@tanstack/react-router';
import { Power } from 'lucide-react';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';

import logo from '@/assets/images/logo-white.png';
import { NavigationCell } from '@/components/navigation-cell';
import { Accordion, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { auth } from '@/lib/firebase';
import { formulesLinks, inscriptionsLinks, settingsLinks } from '@/utils/constants';

export const AdminLayout = () => {
  const navigate = useNavigate();
  const router = useRouterState();

  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <header className="flex h-20 justify-between bg-previsionorange px-4 py-2 text-white">
        <img src={logo} alt="logo" className="my-auto hidden h-14 w-auto md:block" />
        {/* Afficher le bouton de toggle seulement sur les appareils mobiles */}
        <div className="my-auto flex md:hidden">
          <button onClick={() => setNavOpen(!navOpen)}>
            {navOpen ? <X size={30} /> : <Menu size={30} />}
          </button>
        </div>
        <h1 className="my-auto text-xl font-semibold">Dashboard admin</h1>
        <Button
          onClick={async () => {
            await auth.signOut();
            navigate({
              to: '/login',
              search: {
                origin: router.location.pathname,
              },
            });
          }}
          className="my-auto bg-previsionorange text-white hover:bg-white hover:text-previsionorange md:space-x-3 md:border md:border-white"
          size="sm"
        >
          <p className="hidden md:block">Logout</p> <Power />
        </Button>
      </header>
      <main className="flex w-full">
        <Sheet open={navOpen} onOpenChange={setNavOpen}>
          <SheetContent side="left" className="w-80 px-0">
            <SheetHeader>
              <SheetTrigger>
                <SheetTitle className="text-xl font-semibold text-previsionorange">
                  Navigation
                </SheetTitle>
                <SheetDescription>Cliquez pour fermer</SheetDescription>
              </SheetTrigger>
            </SheetHeader>
            <NavContent />
          </SheetContent>
        </Sheet>
        <nav className="hidden w-80 bg-white px-2 md:block">
          <NavContent />
        </nav>
        <Outlet />
      </main>
    </>
  );
};

const NavContent = () => {
  return (
    <Accordion
      type="multiple"
      className="h-[calc(100dvh-5rem)] w-full overflow-y-auto bg-white px-2 md:block"
      defaultValue={['inscription', 'formules', 'settings']}
    >
      <AccordionItem value="inscription">
        <AccordionTrigger className="font-medium hover:no-underline">
          Inscriptions
        </AccordionTrigger>
        {inscriptionsLinks.map((link) => (
          <NavigationCell
            key={link.title}
            title={link.title}
            path={link.path}
            icon={link.icon}
          />
        ))}
      </AccordionItem>
      <AccordionItem value="formules">
        <AccordionTrigger className="font-medium hover:no-underline">
          Formules
        </AccordionTrigger>
        {formulesLinks.map((link) => (
          <NavigationCell
            key={link.title}
            title={link.title}
            path={link.path}
            icon={link.icon}
          />
        ))}
      </AccordionItem>
      <AccordionItem value="settings">
        <AccordionTrigger className="font-medium hover:no-underline">
          Paramètres
        </AccordionTrigger>
        {settingsLinks.map((link) => (
          <NavigationCell
            key={link.title}
            title={link.title}
            path={link.path}
            icon={link.icon}
          />
        ))}
      </AccordionItem>
    </Accordion>
  );
};
