import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import { LoaderCircle } from 'lucide-react';
import { FC, FormEvent, useState } from 'react';

import { Button } from '@/components/ui/button';
import { LoaderPage } from '@/pages/loader';

export const CheckoutForm: FC = () => {
  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/callbacks/payment',
      },
    });

    if (result.error) {
      console.error(result.error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="h-fit">
      {!isReady && (
        <LoaderPage
          bgClassName="bg-white w-full h-32"
          pointClassName="bg-previsionblue"
        />
      )}
      <PaymentElement
        onReady={() => {
          setIsReady(true);
        }}
        className={clsx(isReady ? 'block' : 'hidden')}
      />
      {isReady && (
        <div className="mt-4 flex justify-between">
          <Link to={`/steps/six`}>
            <Button className="w-32" type="button" variant="outlinedBlue">
              Précédent
            </Button>
          </Link>
          <Button
            className="w-32"
            type="submit"
            variant="squaredBlue"
            disabled={!stripe || isSubmitting}
          >
            {isSubmitting ? <LoaderCircle className="animate-spin" /> : 'Payer'}
          </Button>
        </div>
      )}
    </form>
  );
};
