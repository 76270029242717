import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

import { db } from '@/lib/firebase';
import {
  FileType,
  Formule,
  InscriptionRow,
  PaymentSchedule,
  RecordFile,
} from '@/types/types';

export const inscriptionsBuilder = async (
  status: 'pending' | 'submitted' | 'archived' | 'cancelled',
  sortedBy:
    | 'createdAt'
    | 'formule'
    | 'student'
    | 'cost'
    | 'paid'
    | 'deposit'
    | 'paymentStatus'
    | 'fileStatus'
    | 'stripeID'
    | 'isRecordValidated',
  sortOrder: 'asc' | 'desc',
) => {
  const colRef = collection(db, `inscriptions`);
  const firebaseQuery = query(
    colRef,
    where('recordStatus', '==', status),
    orderBy(sortedBy, sortOrder),
  );

  const querySnapshot = await getDocs(firebaseQuery);
  const data: InscriptionRow[] = [];

  const yearList: string[] = [];
  const formuleList: string[] = [];

  for (const doc of querySnapshot.docs) {
    if (!doc.exists()) continue;
    if (!doc.data().student) continue;

    const depositStatus =
      doc.data().payments &&
      doc.data().payments.schedule &&
      doc.data().payments.schedule[0].paymentStatus &&
      doc.data().payments.schedule[0].paymentStatus;

    const totalPaid =
      doc.data().payments && doc.data().payments.totalPaid
        ? doc.data().payments.totalPaid
        : 0;

    const depositPayment =
      doc.data().payments &&
      doc
        .data()
        .payments.schedule.find(
          (payment: PaymentSchedule) => payment.paymentType === 'accompte',
        );

    const realCostPayment =
      doc.data().payments &&
      doc
        .data()
        .payments.schedule.find(
          (payment: PaymentSchedule) => payment.paymentType === 'total',
        );

    const scolarityReportsTypesArray: FileType[][] = [
      [
        'scolarityElevenGradeReportOne',
        'scolarityElevenGradeReportTwo',
        'scolarityElevenGradeReportThree',
      ],
      ['scolarityReportOne', 'scolarityReportTwo', 'scolarityReportThree'],
    ];

    const ScolarityReportMatrixBoolean = scolarityReportsTypesArray.map(
      (array: FileType[]) => {
        return array.map((type: FileType) => {
          if (!doc.data().files || doc.data().files.length === 0) return false;
          return doc.data().files.some((file: RecordFile) => file.type === type);
        });
      },
    );

    data.push({
      id: doc.id as string,
      name: `${doc.data().student.lastname} ${doc.data().student.firstname}`,
      createdAt: doc.data().createdAt.toDate() ?? null,
      formuleDate: doc.data().formule ? doc.data().formule.year : 'Non renseigné',
      formuleTitle: doc.data().formule ? doc.data().formule.title : 'Non renseigné',
      cost: doc.data().formule ? doc.data().formule.cost : 0,
      realCost: realCostPayment ? realCostPayment.paymentAmount : 0,
      paid: totalPaid,
      deposit: 0,
      paymentStatus: depositStatus || 'En attente',
      fileStatus: ScolarityReportMatrixBoolean,
      stripeID: depositPayment ? depositPayment.paymentReference : null,
      isRecordValidated: doc.data().isRecordValidated || false,
    });

    if (
      doc.data().formule &&
      doc.data().formule.year &&
      !yearList.includes(doc.data().formule.year)
    ) {
      yearList.push(doc.data().formule.year);
    }

    if (
      doc.data().formule &&
      doc.data().formule.title &&
      !formuleList.includes(doc.data().formule.title)
    ) {
      formuleList.push(doc.data().formule.title);
    }
  }

  return { data, yearList, formuleList };
};

export const formulesBuilder = async (
  status: 'published' | 'unpublished' | 'archived',
) => {
  const colRef = collection(db, `formules`);
  const firebaseQuery = query(colRef, where('status', '==', status));

  const querySnapshot = await getDocs(firebaseQuery);
  const data: Formule[] = [];
  const yearList: string[] = [];
  const formuleList: string[] = [];

  querySnapshot.forEach((doc) => {
    if (!doc.exists()) return null;
    data.push({
      id: (doc.id as string) || null,
      ...doc.data(),
    } as Formule);

    const year = doc.data().year;
    if (!yearList.includes(year)) {
      yearList.push(year);
    }

    const type = doc.data().type;
    if (!formuleList.includes(type)) {
      formuleList.push(type);
    }
  });

  return {
    formules: data,
    yearList,
    formuleList,
  };
};
