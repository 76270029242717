import { Link, useNavigate } from '@tanstack/react-router';
import ls from 'localstorage-slim';

import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { saveDocument, saveFileArrayFromForm } from '@/data/form-handlers';
import { RecordFile, SchoolRecord } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const StudentFiles = () => {
  const navigate = useNavigate();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const getLocal: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;
        const studentFiles: RecordFile[] = getLocal?.files || [];

        if (!getLocal?.files && getLocal != null) {
          const newRecord = {
            files: studentFiles,
            formProgress:
              getLocal && getLocal.formProgress >= 5 ? getLocal.formProgress : 5,
            updatedAt: new Date(),
          };

          const res = await saveFileArrayFromForm(newRecord);
          if (res) {
            navigate({
              to: '/steps/six',
            });
          }

          return;
        } else {
          navigate({
            to: '/steps/six',
          });
        }
      }}
      className="h-fit w-full bg-white px-7 py-4 text-black/60 lg:w-2/3"
    >
      <SectionTitle title="Documents" />
      <p className="mb-8">
        Afin de finaliser votre dossier, nous avons besoin de certains documents (une
        photo de vos documents suffit).
        <br />
        <span className="text-previsionorange">
          Si vous ne les avez pas tous, vous pourrez les envoyer plus tard à l'adresse
          mail{' '}
          <a
            className="text-previsionorange transition-all hover:opacity-60"
            href="mailto:contact@prepa-prevision.fr"
          >
            contact@prepa-prevision.fr
          </a>
          .
        </span>
      </p>
      <section className="mb-8 flex flex-col space-y-4">
        <div>
          <FormItem className="focus-within:text-previsionblue">
            <p className="text-base font-bold text-previsionblue">Photo d'identité</p>

            <Input
              type="file"
              accept="image/*, application/pdf"
              onChange={(event) => {
                if (!event.target.files) return;
                saveDocument(
                  event.target.files && event.target.files[0],
                  'identityPicture',
                );
              }}
            />
          </FormItem>
        </div>
        <div>
          <FormItem className="focus-within:text-previsionblue">
            <p className="text-base font-bold text-previsionblue">Pièce d'identité</p>

            <Input
              type="file"
              accept="image/*, application/pdf"
              onChange={(event) => {
                if (!event.target.files) return;
                saveDocument(
                  event.target.files && event.target.files[0],
                  'identityDocument',
                );
              }}
            />
          </FormItem>
        </div>
      </section>
      <section className="mb-8 flex flex-col space-y-4">
        <SectionTitle title="Bulletins scolaires de Première" />
        <p>
          Photocopies des bulletins trimestriels de Première en votre possession.
          <br />
          <span className="text-previsionorange">
            Si vous ne les avez pas tous, vous pourrez les envoyer plus tard à l'adresse
            mail{' '}
            <a
              className="text-previsionorange transition-all hover:opacity-60"
              href="mailto:contact@prepa-prevision.fr"
            >
              contact@prepa-prevision.fr
            </a>
            .
          </span>
        </p>
        <div>
          <FormItem className="focus-within:text-previsionblue">
            <p className="text-base font-bold text-previsionblue">Trimestre 1</p>

            <Input
              type="file"
              accept="image/*, application/pdf"
              onChange={(event) => {
                if (!event.target.files) return;
                saveDocument(
                  event.target.files && event.target.files[0],
                  'scolarityElevenGradeReportOne',
                );
              }}
            />
          </FormItem>
        </div>
        <div>
          <FormItem className="focus-within:text-previsionblue">
            <p className="text-base font-bold text-previsionblue">Trimestre 2</p>
            <Input
              type="file"
              accept="image/*, application/pdf"
              onChange={(event) => {
                if (!event.target.files) return;
                saveDocument(
                  event.target.files && event.target.files[0],
                  'scolarityElevenGradeReportTwo',
                );
              }}
            />
          </FormItem>
        </div>
        <div>
          <FormItem className="focus-within:text-previsionblue">
            <p className="text-base font-bold text-previsionblue">Trimestre 3</p>

            <Input
              type="file"
              accept="image/*, application/pdf"
              onChange={(event) => {
                if (!event.target.files) return;
                saveDocument(
                  event.target.files && event.target.files[0],
                  'scolarityElevenGradeReportThree',
                );
              }}
            />
          </FormItem>
        </div>
      </section>

      <div className="flex justify-between">
        <Link to={`/steps/four`}>
          <Button type="button" variant="outlinedBlue" className="w-32">
            Précédent
          </Button>
        </Link>
        <Button type="submit" variant="squaredBlue" className="w-32">
          Suivant
        </Button>
      </div>
    </form>
  );
};
